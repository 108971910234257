import React, { MouseEventHandler } from 'react'
import GenericTablePage from '@templates/Generic/Table'
import { deleteHosts, getHosts } from '@utils/api/hosts'
import { navigate, PageProps } from 'gatsby'
import { Button, ButtonGroup } from '@mui/material'
import { Add, Delete, Refresh, VpnKey } from '@mui/icons-material'
import useApi from '@hooks/useApi'
import {
  GridComponentProps,
  GridRowParams,
  GridValueGetterParams
} from '@mui/x-data-grid-pro'
import {
  chipRenderer,
  digitRenderer,
  hostActiveRenderer,
  hostOsRenderer,
  hostStatusRenderer,
  quickColumn,
  tooltipRenderer
} from '@utils/tables'
import { VscDebugStepOver } from 'react-icons/vsc'
import { hostPresets } from '@utils/hosts'
import moment from 'moment'

const columns: GridComponentProps['columns'] = [
  quickColumn({ field: 'id' }),
  {
    field: 'host_id',
    headerName: 'Host Id',
    width: 120,
    hide: true,
    type: 'enhancedString'
  },
  {
    field: 'status_icon',
    headerName: 'Status',
    width: 100,
    align: 'left',
    headerAlign: 'left',
    type: 'actions',
    getActions: hostStatusRenderer
  },
  {
    field: 'hostname',
    headerName: 'Target Address',
    width: 150,
    type: 'enhancedString'
  },
  {
    field: 'os_info_node',
    headerName: 'Hostname',
    width: 150,
    type: 'enhancedString',
    renderCell: tooltipRenderer
  },
  {
    field: 'os_info_os_release_pretty_name',
    headerName: 'OS',
    width: 200,
    renderCell: hostOsRenderer,
    type: 'enhancedString'
  },

  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    width: 120,
    renderCell: hostActiveRenderer
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 170,
    renderCell: chipRenderer,
    sortable: false,
    type: 'enhancedString'
  },
  {
    field: 'jump_hosts',
    headerName: 'Jump Hosts',
    width: 170,
    renderCell: chipRenderer,
    sortable: false,
    type: 'enhancedString'
  },
  {
    field: 'authentication_status',
    headerName: 'Auth Status',
    width: 150,
    type: 'enhancedString'
  },
  {
    field: 'credentials_id',
    headerName: 'Credential',
    width: 120,
    type: 'enhancedString'
  },
  {
    field: 'date_last_seen',
    headerName: 'Last Seen',
    type: 'dateTime',
    width: 150
  },
  {
    field: 'isOffline',
    headerName: 'Is Offline',
    type: 'boolean',
    hide: true,
    valueGetter: (params: GridValueGetterParams) => {
      const lastSeen = moment(params.row.date_last_seen)

      const threshold = moment().subtract(24, 'hours')

      return lastSeen.isBefore(threshold)
    }
  },
  {
    field: 'isUnknown',
    headerName: 'Is Unknown',
    type: 'boolean',
    hide: true,
    valueGetter: (params: GridValueGetterParams) => {
      const lastSeen = params.row.date_last_seen
      return lastSeen == '0001-01-01T00:00:00Z'
    }
  },
  quickColumn({ field: 'first_seen_ip_addr' }),
  quickColumn({ field: 'last_seen_ip_addr', hide: false }),
  quickColumn({ field: 'os_hardware_cpu_bugs' }),
  // Todo Fix
  {
    field: 'os_hardware_cpu_cores',
    headerName: 'CPU Cores',
    type: 'number',
    width: 150
  },
  {
    field: 'os_hardware_cpu_mhz',
    headerName: 'CPU MHz',
    type: 'number',
    width: 150
  },
  quickColumn({ field: 'os_hardware_cpu_mhz' }),
  quickColumn({ field: 'os_hardware_cpu_model_name' }),
  quickColumn({ field: 'os_hardware_dmi_bios_vendor' }),
  quickColumn({ field: 'os_hardware_dmi_chassis_vendor' }),
  quickColumn({ field: 'os_hardware_dmi_product_family' }),
  quickColumn({ field: 'os_hardware_dmi_product_name' }),
  quickColumn({ field: 'os_hardware_dmi_sys_vendor' }),
  quickColumn({ field: 'os_info_arch', headerName: 'Arch', hide: false }),
  quickColumn({ field: 'os_info_machine' }),
  quickColumn({ field: 'os_info_os_release_name' }),
  quickColumn({ field: 'os_info_os_release_version' }),
  quickColumn({ field: 'os_info_os_release_version_codename' }),
  quickColumn({ field: 'os_info_release' }),
  quickColumn({ field: 'os_info_uptime_date' }),
  {
    field: 'os_info_uptime_days',
    headerName: 'Uptime Days',
    type: 'number',
    width: 150
  },
  quickColumn({ field: 'os_performance_cpu_fifteen_min', type: 'number' }),
  quickColumn({
    field: 'os_performance_cpu_fifteen_min_core_adjusted',
    type: 'number'
  }),
  quickColumn({ field: 'os_performance_cpu_five_min', type: 'number' }),
  quickColumn({
    field: 'os_performance_cpu_five_min_core_adjusted',
    type: 'number'
  }),
  quickColumn({ field: 'os_performance_cpu_one_min', type: 'number' }),
  quickColumn({
    field: 'os_performance_cpu_one_min_core_adjusted',
    type: 'number'
  }),
  quickColumn({ field: 'queue_name', hide: false }),
  quickColumn({ field: 'sandfly_whitelist' }),
  quickColumn({ field: 'ssh_key_fingerprint' }),
  {
    field: 'ssh_port',
    headerName: 'SSH Port',
    width: 180,
    type: 'number',
    hide: true,
    renderCell: digitRenderer
  }
]

const HostsPage = ({}: PageProps) => {
  const api = useApi({ apiMethod: getHosts })

  const deleteApi = useApi<any>({
    apiMethod: deleteHosts,
    requestOnMount: false
  })

  const handleRefresh: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    api.refresh()
  }

  return (
    <GenericTablePage
      breadcrumbs={[
        {
          title: 'Hosts'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button
            role="button"
            endIcon={<Refresh />}
            href="#"
            onClick={handleRefresh}
          >
            Refresh
          </Button>
          <Button endIcon={<VpnKey />} href="/hosts/credentials/">
            Credentials
          </Button>
          <Button
            //feature="jump_hosts"
            endIcon={<VscDebugStepOver />}
            href="/hosts/jumphost/"
          >
            Jump Hosts
          </Button>
          <Button endIcon={<Add />} href="/hosts/add" data-cy="addHost">
            Add Host
          </Button>
        </ButtonGroup>
      }
      gridOptions={{
        columns,
        initialSort: [
          {
            field: 'active',
            sort: 'desc'
          },
          {
            field: 'hostname',
            sort: 'asc'
          }
        ],
        onRowClick: (param: { id: string }) => {
          navigate(`/hosts/host/${param.id}/`)
        },
        getRowClassName: ({ getValue, id, row }: GridRowParams): string => {
          const classes = []

          const { results_alert, results_error, results_pass } = row

          const isOffline = getValue(id, 'isOffline')
          const isUnknown = getValue(id, 'isUnknown')

          results_alert > 0 && classes.push('DataGrid__row--has-alerts')

          results_error > 0 && classes.push('DataGrid__row--has-errors')

          results_pass > 0 && classes.push('DataGrid__row--has-passes')

          isOffline && classes.push('DataGrid__row--is-stale')

          isUnknown && classes.push('DataGrid__row--is-unknown')

          return classes.join(' ')
        }
      }}
      keyProp={'host_id'}
      title="Hosts"
      api={api}
      bulkActions={[
        {
          text: 'Delete Selected',
          icon: <Delete fontSize="small" />,
          action: async (selection: string[]) => {
            await deleteApi.makeRequest(selection).then(() => {
              deleteApi.enqueueSnackbar('Host(s) successfully deleted', {
                variant: 'success'
              })
            })
          },
          autoRefresh: true
        }
      ]}
      presets={hostPresets}
    />
  )
}

export default HostsPage
